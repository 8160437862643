import { Brand } from "../brand";
import { GroupId } from "../group/group.dto";
import { UserId } from "../user/user.dto";

export type CompanyUserId = Brand<number, "CompanyUserId">;

export interface CompanyUser {
  id: CompanyUserId;
  name: string;
  user_id: UserId;
  group_id: GroupId;
  is_outsourced_member: boolean;
  is_super_user: boolean;
  has_email: boolean;
  is_deactivate: boolean;
  project_write: boolean;
  project_read: number;
  estimate_write: number;
  estimate_read: boolean;
  attachment_read: number;
  billing_write: boolean;
  billing_read: boolean;
  schedule_write: number;
  schedule_read: number;
  client_write: boolean;
  client_read: boolean;
  master_write: boolean;
  master_read: boolean;
  unit_price_per_day: number;
  calendar_start_day_of_week: DayOfWeek;
  number_of_project_note_lines_to_display: number;
}

export enum PermissionSetKeyName {
  OutsourcedMember = "outsourced_member",
  Other = "other",
}

export interface CompanyUserCreateRequest {
  name: string;
  email: string;
  permissionSetKeyName: PermissionSetKeyName;
  groupName?: string;
}

export interface CompanyUserRequest {
  name?: string;
  groupId?: number;
  unitPricePerDay?: number;
  displayOrderPosition?: number;
  isDeactivate?: boolean;
}

export type CompanyUserCreateResponse = CompanyUser;

export type CompanyUserUpdateResponse = CompanyUser;

export type CompanyUserIndexResponse = Array<CompanyUser>;

// 月火水木金土日
export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const initialCompanyUserRequest: CompanyUserCreateRequest = {
  name: "",
  email: "",
  permissionSetKeyName: PermissionSetKeyName.OutsourcedMember,
};
