export interface PersonalSetting {
  calendar_start_day_of_week: DayOfWeek;
  number_of_project_note_lines_to_display: number;
  is_microsoft_signed_in: boolean;
}

export const initialPersonalSetting: PersonalSetting = {
  calendar_start_day_of_week: 0,
  number_of_project_note_lines_to_display: 0,
  is_microsoft_signed_in: false,
};

export interface PersonalSettingUpdateRequest {
  calendarStartDayOfWeek?: number;
  numberOfProjectNoteLinesToDisplay?: number;
}

export type PersonalSettingShowResponse = PersonalSetting;

// 月火水木金土日
export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;
