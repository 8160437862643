import { ApiClient } from "data-access/ApiClient";
import {
  AttendanceSettingIndexResponse,
  AttendanceSettingUpdateRequest,
} from "features/company-settings/types/attendance-setting/attendance_setting.dto";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";

export const attendanceSettingRepository = {
  index(): Promise<AttendanceSettingIndexResponse> {
    return ApiClient.get("/api/v1/attendances/setting").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  update(body: AttendanceSettingUpdateRequest): Promise<void> {
    return ApiClient.put("/api/v1/attendances/setting", updateBody(body)).then((res) => res.data);
  },
};

const updateBody = (body: AttendanceSettingUpdateRequest) => {
  return {
    starting_date_of_year: body.startingDateOfYear,
    starting_wday_of_week: body.startingWdayOfWeek,
    legal_holiday_wday: body.legalHolidayWday,
  };
};
