import { useState, useEffect } from "react";
import {
  Backdrop,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Layout } from "components/templates/layout";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { handleError } from "utils/errorHandler";
import { formatDateUtil } from "utils/formatDateUtil";
import { attendanceSettingRepository } from "../api/attendance-setting/attendance_setting.repository";
import {
  AttendanceSettingIndexResponse,
  DayOfWeek,
} from "../types/attendance-setting/attendance_setting.dto";

export const AttendanceSetting = () => {
  const dispatch = useAppDispatch();
  const [isFetchLoading, setIsFetchLoading] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<number>(0);
  const [selectedDay, setSelectedDay] = useState<number>(0);
  const [daysInMonth, setDaysInMonth] = useState<number>(0);
  const [startingWday, setStartingWday] = useState<DayOfWeek>(0);
  const [legalHoliday, setLegalHoliday] = useState<DayOfWeek>(0);

  const { data, isValidating } = useSWR<AttendanceSettingIndexResponse>(
    "/api/v1/attendances/settings",
    attendanceSettingRepository.index,
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (data) {
      const formatDate = new Date(data.startingDateOfYear);
      const month = formatDate.getMonth() + 1;
      const day = formatDate.getDate();

      setSelectedMonth(month);
      setSelectedDay(day);
      setStartingWday(data.startingWdayOfWeek);
      setLegalHoliday(data.legalHolidayWday);

      // 選択された月の日数を計算して設定
      const daysInSelectedMonth = new Date(new Date().getFullYear(), month, 0).getDate();
      setDaysInMonth(daysInSelectedMonth);
    }
  }, [data]);

  const handleMonthChange = async (e: SelectChangeEvent<number>) => {
    setIsFetchLoading(true);
    const newMonth = Number(e.target.value);
    // 新しい月の日数を計算
    const daysInNewMonth = new Date(new Date().getFullYear(), newMonth, 0).getDate();
    // 現在選択されている日が新しい月の日数を超えていないかチェック
    const newDay = Math.min(selectedDay, daysInNewMonth);

    try {
      const newDate = new Date(new Date().getFullYear(), newMonth - 1, newDay);
      await attendanceSettingRepository.update({
        startingDateOfYear: formatDateUtil(newDate, "yyyy-MM-dd"),
      });
      setSelectedMonth(newMonth);
      setSelectedDay(newDay);
      setDaysInMonth(daysInNewMonth);
      dispatch(mainOperations.updateSuccessMessage("起算日を変更しました"));
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "起算日の変更に失敗しました",
      );
    } finally {
      setIsFetchLoading(false);
    }
  };

  const handleDayChange = async (e: SelectChangeEvent<number>) => {
    setIsFetchLoading(true);
    const newDay = Number(e.target.value);

    try {
      const newDate = new Date(new Date().getFullYear(), selectedMonth - 1, newDay);
      await attendanceSettingRepository.update({
        startingDateOfYear: formatDateUtil(newDate, "yyyy-MM-dd"),
      });
      setSelectedDay(newDay);
      dispatch(mainOperations.updateSuccessMessage("起算日を変更しました"));
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "起算日の変更に失敗しました",
      );
    } finally {
      setIsFetchLoading(false);
    }
  };

  const handleStartingWdayChange = async (e: SelectChangeEvent<DayOfWeek>) => {
    setIsFetchLoading(true);
    try {
      await attendanceSettingRepository.update({
        startingWdayOfWeek: e.target.value as DayOfWeek,
      });
      setStartingWday(e.target.value as DayOfWeek);
      dispatch(mainOperations.updateSuccessMessage("起算曜日を変更しました"));
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "起算曜日の変更に失敗しました",
      );
    } finally {
      setIsFetchLoading(false);
    }
  };

  const handleLegalHolidayChange = async (e: SelectChangeEvent<DayOfWeek>) => {
    setIsFetchLoading(true);
    try {
      await attendanceSettingRepository.update({
        legalHolidayWday: e.target.value as DayOfWeek,
      });
      setLegalHoliday(e.target.value as DayOfWeek);
      dispatch(mainOperations.updateSuccessMessage("法定休日を変更しました"));
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "法定休日の変更に失敗しました",
      );
    } finally {
      setIsFetchLoading(false);
    }
  };

  return (
    <Layout>
      <Backdrop
        sx={{ color: theme.palette.grayScale[0], zIndex: () => 9999 }}
        open={isFetchLoading || isValidating}
        invisible
      >
        <CircularProgress />
      </Backdrop>

      <Typography fontWeight="bold" fontSize="24px" mb="20px">
        勤怠管理設定
      </Typography>
      <div style={{ display: "flex", flexDirection: "column", gap: "8px", marginBottom: "24px" }}>
        <Typography fontWeight="bold">起算日</Typography>
        <Typography sx={{ fontSize: "12px", color: theme.palette.grayScale[700] }}>
          打刻データの起算日を設定できます。
        </Typography>
        <div>
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            sx={{ height: "40px", mr: "8px" }}
          >
            {[...Array(12)].map((_, i) => (
              <MenuItem key={i} value={i + 1}>
                {i + 1}月
              </MenuItem>
            ))}
          </Select>
          <Select value={selectedDay} onChange={handleDayChange} sx={{ height: "40px" }}>
            {[...Array(daysInMonth)].map((_, i) => (
              <MenuItem key={i} value={i + 1}>
                {i + 1}日
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "8px", marginBottom: "24px" }}>
        <Typography fontWeight="bold">起算曜日</Typography>
        <Typography sx={{ fontSize: "12px", color: theme.palette.grayScale[700] }}>
          打刻データの起算曜日を設定できます。
        </Typography>
        <Select
          value={startingWday}
          onChange={handleStartingWdayChange}
          sx={{ height: "40px", width: "120px" }}
        >
          {startingWdayOptions.map((day) => (
            <MenuItem key={day.value} value={day.value}>
              {day.label}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "8px", marginBottom: "24px" }}>
        <Typography fontWeight="bold">法定休日</Typography>
        <Typography sx={{ fontSize: "12px", color: theme.palette.grayScale[700] }}>
          自社の法定休日を設定できます。
        </Typography>
        <Select
          value={legalHoliday}
          onChange={handleLegalHolidayChange}
          sx={{ height: "40px", width: "120px" }}
        >
          {legalHolidayOptions.map((day) => (
            <MenuItem key={day.value} value={day.value}>
              {day.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    </Layout>
  );
};

const baseWeekdays = [
  { value: 0, label: "日曜日" },
  { value: 1, label: "月曜日" },
  { value: 2, label: "火曜日" },
  { value: 3, label: "水曜日" },
  { value: 4, label: "木曜日" },
  { value: 5, label: "金曜日" },
  { value: 6, label: "土曜日" },
];

const startingWdayOptions = [...baseWeekdays];
const legalHolidayOptions = [{ value: -1, label: "未設定" }, ...baseWeekdays];
