import { useEffect, useState } from "react";
import * as React from "react";
import { FileDownloadOutlined, Link, Visibility } from "@mui/icons-material";
import { Box, Typography, Tooltip, IconButton, Divider, Switch } from "@mui/material";
import { DeleteIcon } from "components/icon/delete-icon";
import { FileIcon } from "components/icon/file-icon";
import { FileDropzone } from "components/molecules/file-dropzone";
import {
  AttachmentResponse,
  ProjectAttachmentId,
} from "data-access/repositories/project/attachment/attachment.dto";
import { attachmentRepository } from "data-access/repositories/project/attachment/attachment.repository";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { imagePreviewModalOperations } from "store/image-preview-modal/operations";
import { mainOperations } from "store/main/operations";
import { ProjectSidebarState } from "store/project-sidebar/slice";
import useSWR from "swr";
import {
  PREVIEWABLE_EXCEL_EXTENSION,
  IMAGE_EXTENSIONS,
  PDF_EXTENSION,
  TIF_EXTENSION,
} from "utils/constant";
import { handleError } from "utils/errorHandler";
import { fileDownload } from "utils/fileDownload";
import { formatDateUtil } from "utils/formatDateUtil";
import { isContainExtensions } from "utils/isContainExtensions";

interface MainProps {
  state: ProjectSidebarState;
  onUpload: (files: File[], attachmentTypeId?: string) => void;
  onDelete: (id: ProjectAttachmentId, type: string) => void;
  onCheck: (e: React.ChangeEvent<HTMLInputElement>, attachmentId: number) => void;
}

export const ProjectSidebarAttachmentBlock = (props: MainProps) => {
  const { state, onUpload, onDelete, onCheck } = props;

  const { data: attachmentTypes, mutate } = useSWR(
    state.project.id ? `/api/v1/projects/${state.project.id}/attachments/by_attachment_type` : null,
    () => attachmentRepository.byAttachmentType(state.project.id),
  );

  useEffect(() => {
    mutate();
  }, [state.project.id, state.isAttachmentSubmitted]);

  return (
    <>
      {attachmentTypes &&
        attachmentTypes.map((attachmentType, index) => (
          <Box key={index}>
            <Typography sx={{ mt: "1rem", color: theme.palette.grayScale[700] }}>
              {attachmentType.name}
            </Typography>
            <FileDropzone
              onDrop={onUpload}
              attachmentTypeId={attachmentType.id}
              sx={{ mt: "0.5rem" }}
            />
            {attachmentType.attachments.map((attachment) => (
              <AttachmentBlock
                key={attachment.id}
                attachment={attachment}
                onDelete={onDelete}
                isPublishToMobileName="IsPublishToMobile"
                onCheck={onCheck}
                state={state}
              />
            ))}
          </Box>
        ))}
    </>
  );
};

interface Props {
  sx?: object;
  state: ProjectSidebarState;
  attachment: AttachmentResponse;
  onDelete: (id: ProjectAttachmentId, type: string) => void;
  isPublishToMobileName: string;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>, id: ProjectAttachmentId) => void;
}

const AttachmentBlock = (props: Props) => {
  const dispatch = useAppDispatch();
  const [copyTip, setCopyTip] = useState<string>("ファイルのリンクをコピーします");

  const handleClickPreview = async () => {
    if (isContainExtensions(props.attachment.file_name, [PDF_EXTENSION])) {
      window.open(props.attachment.file_url);
      return;
    }
    if (isContainExtensions(props.attachment.file_name, [...IMAGE_EXTENSIONS, ...TIF_EXTENSION])) {
      dispatch(imagePreviewModalOperations.open());
      dispatch(
        imagePreviewModalOperations.setImage({
          name: props.attachment.file_name,
          url: props.attachment.file_url,
        }),
      );
      return;
    }
    if (isContainExtensions(props.attachment.file_name, PREVIEWABLE_EXCEL_EXTENSION)) {
      dispatch(mainOperations.updateIsLoading(true));
      try {
        const result = await attachmentRepository.excelFilePreview(
          props.state.project.id,
          props.attachment.id as ProjectAttachmentId,
        );
        window.open(result.url, "_blank");
      } catch (error) {
        handleError(
          error,
          (errorMessage: string) => {
            dispatch(mainOperations.updateErrorMessage(errorMessage));
          },
          "ファイルのプレビューに失敗しました",
        );
      } finally {
        dispatch(mainOperations.updateIsLoading(false));
      }
    }
  };

  const handleClickCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(props.attachment.file_url);
      setCopyTip("コピーしました");
    } catch (error: any) {
      alert((error && error.message) || "コピーに失敗しました");
    }
  };
  const handleOpenInfoTitleTip = () => {
    setCopyTip("ファイルのリンクをコピーします");
  };

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grayScale[300]}`,
        borderRadius: "5px",
        backgroundColor: theme.palette.customPrimary[50],
        p: "1rem",
        mt: "1rem",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex" }}>
          <FileIcon fileName={props.attachment.file_name} sx={{ width: "1.8rem" }} />
          <Box sx={{ ml: "0.5rem", width: "9rem", wordWrap: "break-word" }}>
            <Typography component="span">{props.attachment.file_name}</Typography>
            <Typography
              fontSize="0.85rem"
              sx={{ mt: "0.5rem", color: theme.palette.grayScale[700] }}
            >
              {formatDateUtil(new Date(props.attachment.created_at), "yyyy年MM月dd日")}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            ml: "0.5rem",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "4.3rem",
          }}
        >
          {isContainExtensions(props.attachment.file_name, [
            PDF_EXTENSION,
            ...IMAGE_EXTENSIONS,
            ...TIF_EXTENSION,
            ...PREVIEWABLE_EXCEL_EXTENSION,
          ]) && (
            <Tooltip title="プレビュー" placement="top" arrow>
              <IconButton size="small" onClick={handleClickPreview}>
                <Visibility />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={copyTip} onOpen={handleOpenInfoTitleTip} placement="top" arrow>
            <IconButton size="small" onClick={handleClickCopyLink}>
              <Link />
            </IconButton>
          </Tooltip>
          <Tooltip title="ダウンロード" placement="top" arrow>
            <IconButton
              size="small"
              onClick={() => fileDownload(props.attachment.file_url, props.attachment.file_name)}
            >
              <FileDownloadOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="削除" placement="top" arrow>
            <IconButton
              size="small"
              onClick={() => props.onDelete(props.attachment.id, "projectAttachment")}
            >
              <DeleteIcon color={theme.palette.grayScale[700]} size={22} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Divider sx={{ my: "8px" }} />
      <div>
        <Typography component="span">全員に共有</Typography>
        <Switch
          onChange={(e) => props.onCheck(e, props.attachment.id)}
          checked={props.attachment.is_publish_to_mobile}
        />
      </div>
    </Box>
  );
};
