import { useMemo, useState } from "react";
import { Close, ContentCopy } from "@mui/icons-material";
import {
  AutocompleteChangeReason,
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from "@mui/material";
import { CustomAutocomplete } from "components/atoms/custom-autocomplete";
import { DeleteIcon } from "components/icon/delete-icon";
import { DuplicateIcon } from "components/icon/duplicate-icon";
import { StatusTypeLabel } from "components/label/status-type-label";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { Building, BuildingId } from "data-access/repositories/building/building.dto";
import { Client, ClientId } from "data-access/repositories/client/client.dto";
import { ProjectUpdateRequest } from "data-access/repositories/project/project.dto";
import { ProjectBilling } from "data-access/repositories/project/project_billing/project_billing.dto";
import { ProjectStatusType } from "data-access/repositories/project_status_type/project_status_type.dto";
import { theme } from "extensions/theme";
import { deleteConfirmDialogOperations } from "store/delete-confirm-dialog/operations";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";
import { projectSidebarOperations } from "store/project-sidebar/operations";
import { selectProjectSidebar } from "store/project-sidebar/slice";
import { styles } from "./styles";

interface Props {
  projectForm: ProjectUpdateRequest;
  onChange: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
  ) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChangeBuilding: (
    event: React.SyntheticEvent<Element, Event>,
    value: {
      id: BuildingId;
      name: string;
      createOption: boolean;
    } | null,
    reason: AutocompleteChangeReason,
  ) => void;
  onCreateBuilding: () => void;
  onChangeClient: (
    event: React.SyntheticEvent<Element, Event>,
    value: {
      id: ClientId;
      name: string;
      createOption: boolean;
    } | null,
    reason: AutocompleteChangeReason,
  ) => void;
  onCreateClient: () => void;
  onClose: () => void;
  projectStatusTypes: ProjectStatusType[];
  rawClientsData: Client[];
  rawBuildingsData: Building[];
  projectBillings: ProjectBilling[];
  selectedClient: Client | undefined;
}

export const ProjectSidebarHeader = (props: Props) => {
  const {
    projectForm,
    onChange,
    onBlur,
    onChangeBuilding,
    onCreateBuilding,
    onChangeClient,
    onCreateClient,
    onClose,
    projectStatusTypes,
    rawClientsData,
    rawBuildingsData,
    projectBillings,
    selectedClient,
  } = props;
  const classes = styles();
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectProjectSidebar);
  const mainState = useAppSelector(selectMain);
  const [projectNameTip, setProjectNameTip] = useState("案件名をコピーします");
  const [projectNameAndCodeTip, setProjectNameAndCodeTip] =
    useState("案件名・案件番号をコピーします");
  const [projectInfoTip, setProjectInfoTip] = useState("案件情報をコピーします");

  const buildings = rawBuildingsData.map((building) => {
    return {
      id: building.id,
      name: building.name,
      createOption: false,
    };
  });
  const selectedBuilding = useMemo(() => {
    if (buildings.length === 0) return [];
    return buildings.filter((building) => building.id === projectForm.buildingId);
  }, [projectForm.buildingId, buildings]);

  const clients = rawClientsData.map((client) => {
    return {
      id: client.id,
      name: client.name,
      phoneNumber: client.phone_number,
      phoneNumberSecond: client.phone_number_second,
      createOption: false,
    };
  });

  const handleOpenNameTip = () => {
    setProjectNameTip("案件名をコピーします");
  };

  const handleOpenNameAndCodeTip = () => {
    setProjectNameAndCodeTip("案件名・案件番号をコピーします");
  };

  const handleOpenInfoTip = () => {
    setProjectInfoTip("案件情報をコピーします");
  };

  const copyProjectNameToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(`${state.form.name}`);
      setProjectNameTip("コピーしました");
    } catch (error: any) {
      alert((error && error.message) || "コピーに失敗しました");
    }
  };

  const copyProjectNameAndCodeToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(`${state.project.code}：${state.form.name}`);
      setProjectNameAndCodeTip("コピーしました");
    } catch (error: any) {
      alert((error && error.message) || "コピーに失敗しました");
    }
  };

  const copyProjectInfoToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(projectInfo());
      setProjectInfoTip("コピーしました");
    } catch (error: any) {
      alert((error && error.message) || "コピーに失敗しました");
    }
  };

  const handleDuplicate = () => {
    dispatch(projectSidebarOperations.duplicate(state.project.id));
  };

  const handleClickDelete = () => {
    dispatch(
      deleteConfirmDialogOperations.setObject({
        id: state.project.id,
        type: "project",
      }),
    );
    dispatch(deleteConfirmDialogOperations.open());
  };

  const managerText = `現場責任者：${state.project.manager && state.project.manager.name}\n`;
  const addressText = `住所：${state.form.address} ${state.form.addressSecond}\n`;
  const phoneNumberText = `電話番号1：${state.form.phoneNumber}\n`;
  const phoneNumberSecondText = `電話番号2：${state.form.phoneNumberSecond}\n`;
  const faxNumberText = `FAX番号：${state.form.faxNumber}\n`;
  const emailText = `メールアドレス：${state.form.email}\n`;
  const requesterText = `依頼元：${state.form.requester}\n`;
  const noteText = `概要・メモ：${state.form.note}\n`;

  const projectInfo = () => {
    let text: string = "";
    if (state.project.manager) {
      text += managerText;
    }
    if (state.form.address) {
      text += addressText;
    }
    if (state.form.phoneNumber) {
      text += phoneNumberText;
    }
    if (state.form.phoneNumberSecond) {
      text += phoneNumberSecondText;
    }
    if (state.form.faxNumber) {
      text += faxNumberText;
    }
    if (state.form.email) {
      text += emailText;
    }
    if (mainState.company.company_setting.is_printable_report_use && state.form.requester) {
      text += requesterText;
    }
    if (state.form.note) {
      text += noteText;
    }
    return text;
  };

  const customFilterOptions = (
    options: {
      id: ClientId;
      name: string;
      phoneNumber: string;
      phoneNumberSecond: string;
      createOption: boolean;
    }[],
    { inputValue }: { inputValue: string },
  ) => {
    return options.filter(
      (option) =>
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.phoneNumber.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.phoneNumberSecond.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  const handleClose = () => {
    dispatch(projectSidebarOperations.close());
    if (onClose) {
      onClose();
    }
  };

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        pt: "5rem",
        backgroundColor: theme.palette.grayScale[0],
        zIndex: "2",
        boxShadow: "0px 5px 5px -5px rgba(0,0,0,0.5)",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          startIcon={<Close />}
          onClick={handleClose}
          sx={{ color: theme.palette.grayScale[700] }}
        >
          とじる
        </Button>
        <div>
          {mainState.company.company_setting.is_copy_project_name_and_code_use && (
            <Tooltip
              title={projectNameAndCodeTip}
              onOpen={handleOpenNameAndCodeTip}
              placement="top"
              arrow
            >
              <span>
                <Button
                  startIcon={<ContentCopy />}
                  onClick={copyProjectNameAndCodeToClipboard}
                  color="primary"
                  disabled={state.isLoading || !state.isExisting}
                  sx={{ mr: "0.7rem" }}
                >
                  案件名と案件番号をコピー
                </Button>
              </span>
            </Tooltip>
          )}
          <Tooltip title={projectInfoTip} onOpen={handleOpenInfoTip} placement="top" arrow>
            <span>
              <Button
                startIcon={<ContentCopy />}
                onClick={copyProjectInfoToClipboard}
                disabled={!state.isExisting}
                sx={{ mr: "0.7rem" }}
              >
                案件情報をコピー
              </Button>
            </span>
          </Tooltip>
          <Button
            startIcon={<DuplicateIcon />}
            onClick={handleDuplicate}
            color="primary"
            disabled={state.isLoading || !state.isExisting}
            sx={{ mr: "0.7rem" }}
          >
            案件を複製
          </Button>
          <Button
            startIcon={<DeleteIcon size={20} />}
            onClick={handleClickDelete}
            color="error"
            disabled={state.isLoading || !state.isExisting}
          >
            案件を削除
          </Button>
        </div>
      </Box>
      <Box sx={{ my: "16px", display: "flex", gap: "18px" }}>
        <div>
          <CustomFormLabel labelName="案件名" />
          <TextField
            id="name"
            name="name"
            className={classes.textField}
            value={projectForm.name}
            onChange={(e) => onChange(e)}
            onBlur={onBlur}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={projectNameTip} onOpen={handleOpenNameTip} placement="top" arrow>
                    <span>
                      <Button
                        startIcon={<ContentCopy />}
                        onClick={copyProjectNameToClipboard}
                        color="primary"
                        disabled={state.isLoading || !state.isExisting}
                        sx={{ p: 0, justifyContent: "end" }}
                      />
                    </span>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <CustomFormLabel labelName="案件ステータス" />
          <FormControl size="small">
            <Select
              id="projectStatusType"
              name="projectStatusType"
              variant="standard"
              value={projectForm.projectStatusTypeId}
              onChange={(e) => onChange(e)}
              sx={{
                "&.MuiInputBase-root:before": {
                  borderBottom: "none",
                },
              }}
            >
              {projectStatusTypes.map((projectStatusType) => (
                <MenuItem key={projectStatusType.id} value={projectStatusType.id}>
                  <StatusTypeLabel
                    statusType={projectStatusType.name}
                    color={projectStatusType.color_number}
                    sx={{
                      height: "auto",
                      p: "3px 0",
                      width: "120px",
                    }}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <CustomFormLabel labelName="物件" />
          <CustomAutocomplete
            data={buildings}
            value={selectedBuilding[0] || null}
            entityName="物件"
            entityLabel="入力して検索"
            onChange={onChangeBuilding}
            onClick={onCreateBuilding}
            isCreate
            variant="standard"
            isClearable
            sx={{ width: "150px", mb: "20px", "&.MuiAutocomplete-root": { mb: 0 } }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <CustomFormLabel labelName="顧客" />
          <CustomAutocomplete
            data={clients}
            value={selectedClient || null}
            entityName="顧客"
            entityLabel="入力して検索"
            onChange={onChangeClient}
            onClick={onCreateClient}
            isCreate
            variant="standard"
            isClearable
            disabled={projectBillings.length > 0}
            filterOptions={customFilterOptions}
            sx={{ width: "170px", mb: "20px", "&.MuiAutocomplete-root": { mb: 0 } }}
          />
        </div>
      </Box>
    </Box>
  );
};
