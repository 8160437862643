import { useState } from "react";
import * as React from "react";
import { FileDownloadOutlined, Visibility } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Typography,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Divider,
  SelectChangeEvent,
} from "@mui/material";
import { DeleteIcon } from "components/icon/delete-icon";
import { FileIcon } from "components/icon/file-icon";
import { ProjectEstimateId } from "data-access/repositories/project/estimate/estimate.dto";
import { estimateRepository } from "data-access/repositories/project/estimate/estimate.repository";
import { Project } from "data-access/repositories/project/project.dto";
import { User } from "data-access/repositories/user/user.dto";
import { theme } from "extensions/theme";
import { imagePreviewModalOperations } from "store/image-preview-modal/operations";
import { mainOperations } from "store/main/operations";
import { PREVIEWABLE_EXCEL_EXTENSION, IMAGE_EXTENSIONS, PDF_EXTENSION } from "utils/constant";
import { fileDownload } from "utils/fileDownload";
import { formatDateUtil } from "utils/formatDateUtil";
import { halfWidthNumber } from "utils/halfWidthNumber";
import { isContainExtensions } from "utils/isContainExtensions";
import { useAppDispatch } from "../../../store/hooks";

interface Props {
  sx?: object;
  project: Project;
  estimate: {
    id: ProjectEstimateId;
    name: string;
    url: string;
    code: string;
    statusType: string;
    userId?: number;
    uploadedDate: string;
    taxIncludedAmount: number;
    displayAmount: number;
    taxType: string;
  };
  onDelete: (id: ProjectEstimateId, type: string) => void;
  onChange: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
    estimateId: ProjectEstimateId,
  ) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>, estimateId: ProjectEstimateId) => void;
  users: User[];
  handleClick: (estimateId: number, statusType: "decided" | "not_yet_decided") => void;
  isEditing: boolean;
}

export const EstimateBlockProject = (props: Props) => {
  const dispatch = useAppDispatch();
  // TextFieldにて表示の時のみtoLocaleString()を使う場合、Windowsの10,11のテンキー入力(IMEモード)で、二重入力されてしまう。
  // そのため、表示と入力の値を別で保持するようuseStateを使う。
  const [displayAmount, setDisplayAmount] = useState<string | number>(
    props.estimate.displayAmount.toLocaleString(),
  );

  const handleClickPreview = async () => {
    if (isContainExtensions(props.estimate.name, [PDF_EXTENSION])) {
      window.open(props.estimate.url);
      return;
    }
    if (isContainExtensions(props.estimate.name, IMAGE_EXTENSIONS)) {
      dispatch(imagePreviewModalOperations.open());
      dispatch(
        imagePreviewModalOperations.setImage({
          name: props.estimate.name,
          url: props.estimate.url,
        }),
      );
      return;
    }
    if (isContainExtensions(props.estimate.name, PREVIEWABLE_EXCEL_EXTENSION)) {
      dispatch(mainOperations.updateIsLoading(true));
      const result = await estimateRepository.excelFilePreview(
        props.project.id,
        props.estimate.id as ProjectEstimateId,
      );
      window.open(result.url, "_blank");
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grayScale[300]}`,
        borderRadius: "5px",
        backgroundColor:
          props.estimate.statusType === "decided"
            ? theme.palette.customPrimary[100]
            : theme.palette.customPrimary[50],
        mt: "0.5rem",
        p: "1rem",
      }}
    >
      <Box sx={{ display: "flex", mb: "1rem", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex" }}>
          <FileIcon fileName={props.estimate.name} sx={{ width: "1.8rem" }} />
          <Box sx={{ ml: "1rem", width: "150px" }}>
            <Typography
              component="span"
              sx={{
                wordBreak: "break-all",
              }}
            >
              {props.estimate.name}
            </Typography>
            <Typography
              fontSize="0.85rem"
              sx={{ mt: "0.5rem", color: theme.palette.grayScale[700] }}
            >
              {formatDateUtil(new Date(props.estimate.uploadedDate), "yyyy年MM月dd日")}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            ml: "0.5rem",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {isContainExtensions(props.estimate.name, [
            PDF_EXTENSION,
            ...IMAGE_EXTENSIONS,
            ...PREVIEWABLE_EXCEL_EXTENSION,
          ]) && (
            <Tooltip title="プレビュー" placement="top" arrow>
              <IconButton size="small" onClick={handleClickPreview}>
                <Visibility />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="ダウンロード" placement="top" arrow>
            <IconButton
              size="small"
              onClick={() => fileDownload(props.estimate.url, props.estimate.name)}
            >
              <FileDownloadOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="削除" placement="top" arrow>
            <IconButton
              size="small"
              onClick={() => props.onDelete(props.estimate.id, "projectEstimate")}
            >
              <DeleteIcon color={theme.palette.grayScale[700]} size={22} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Divider sx={{ mb: "18px" }} />
      <div style={{ display: "flex", marginBottom: "4px" }}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            width: "60px",
            color: theme.palette.grayScale[700],
          }}
        >
          番号
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>{props.estimate.code}</Typography>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            width: "60px",
            color: theme.palette.grayScale[700],
          }}
        >
          作成者
        </Typography>
        <Select
          id="headerEstimateUserId"
          name="headerEstimateUserId"
          onChange={(event) => props.onChange(event, props.estimate.id)}
          value={props.estimate.userId}
          variant="standard"
          sx={{
            minWidth: "80px",
            fontSize: "14px",
            fontWeight: "500",
            "&.MuiInputBase-root:before": {
              borderBottom: "none",
            },
          }}
        >
          {props.users.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {user.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div style={{ display: "flex", alignItems: "center", marginTop: 0.5 }}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            width: "60px",
            color: theme.palette.grayScale[700],
          }}
        >
          金額
        </Typography>
        <TextField
          id="headerEstimateAmount"
          name="headerEstimateAmount"
          variant="standard"
          value={displayAmount}
          onFocus={() => setDisplayAmount(props.estimate.displayAmount)}
          onChange={(event) => {
            props.onChange(
              {
                ...event,
                target: {
                  ...event.target,
                  value: isNaN(Number(halfWidthNumber(String(event.target.value))))
                    ? "0"
                    : halfWidthNumber(String(event.target.value)),
                  name: "headerEstimateAmount",
                },
              },
              props.estimate.id,
            );
            setDisplayAmount(event.target.value as string);
          }}
          onBlur={(event) => {
            props.onBlur(
              {
                ...event,
                target: {
                  ...event.target,
                  value: isNaN(Number(halfWidthNumber(String(event.target.value))))
                    ? "0"
                    : halfWidthNumber(String(event.target.value)),
                  name: "headerEstimateAmount",
                },
              } as React.FocusEvent<HTMLInputElement>,
              props.estimate.id,
            );
            // 入力値が文字列であればNaNが表示されてしまうため、0を表示する
            setDisplayAmount(
              isNaN(Number(halfWidthNumber(String(props.estimate.displayAmount))))
                ? 0
                : Number(halfWidthNumber(String(props.estimate.displayAmount))).toLocaleString(),
            );
          }}
          onWheel={(event) => event.currentTarget.querySelector("input")?.blur()}
          InputProps={{
            startAdornment: <InputAdornment position="start">¥</InputAdornment>,
          }}
          sx={{
            maxWidth: "140px",
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
          }}
        />
        <Select
          id="headerEstimateTaxType"
          name="headerEstimateTaxType"
          onChange={(event) => props.onChange(event, props.estimate.id)}
          value={props.estimate.taxType}
          variant="standard"
          sx={{
            ml: "8px",
            fontSize: "14px",
            fontWeight: "500",
            "&.MuiInputBase-root:before": {
              borderBottom: "none",
            },
          }}
        >
          <MenuItem value="without_tax">税別</MenuItem>
          <MenuItem value="with_tax">税込</MenuItem>
        </Select>
      </div>
      <div>
        <FormControlLabel
          labelPlacement="end"
          label={
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              この見積りで決定
            </Typography>
          }
          control={
            <Checkbox
              id="estimateStatusType"
              name="estimateStatusType"
              onChange={() =>
                props.handleClick(
                  props.estimate.id,
                  props.estimate.statusType === "decided" ? "not_yet_decided" : "decided",
                )
              }
              checked={props.estimate.statusType === "decided"}
            />
          }
        />
      </div>
    </Box>
  );
};
