import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AutocompleteChangeReason,
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  SelectChangeEvent,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { TitleLabel } from "components/label/title-label";
import { DiscardChangesConfirmDialog } from "components/molecules/discard-changes-confirm-dialog";
import { ImagePreviewModal } from "components/molecules/image-preview-modal";
import { ReportContentPrintModal } from "components/molecules/report-content-print-modal";
import { BillingsInfo } from "components/organisms/billings-info";
import { ProjectSidebarActivity } from "components/organisms/project-sidebar-activity";
import { ProjectSidebarAttachmentBlock } from "components/organisms/project-sidebar-attachment-block";
import { ProjectSidebarContentDetailsBlock } from "components/organisms/project-sidebar-content-details-block";
import { ProjectSidebarEstimateBlock } from "components/organisms/project-sidebar-estimate-block";
import { ProjectSidebarHeader } from "components/organisms/project-sidebar-header";
import { ProjectSidebarPrintableReportBlock } from "components/organisms/project-sidebar-printable-report-block";
import { ProjectSidebarSalesBlock } from "components/organisms/project-sidebar-sales-block";
import { ProjectSidebarScheduleBlock } from "components/organisms/project-sidebar-schedule-block";
import { ProjectSidebarSiteInfo } from "components/organisms/project-sidebar-site-info";
import { ProjectSidebarTitle } from "components/organisms/project-sidebar-title";
import { ProjectSidebarTodo } from "components/organisms/project-sidebar-todo";
import { ProjectSidebarWorkReportBlock } from "components/organisms/project-sidebar-work-report-block";
import { ProjectSidebarClientServiceReportBlock } from "components/organisms/project_sidebar_client_service_report_block";
import { DeleteConfirmDialog } from "components/templates/delete-confirm-dialog";
import { DetailSidebar } from "components/templates/detail-sidebar";
import { ResponseSnackbar } from "components/templates/response-snackbar";
import { buildingRepository } from "data-access/repositories/building/building.repository";
import { Client } from "data-access/repositories/client/client.dto";
import { clientRepository } from "data-access/repositories/client/client.repository";
import { ProjectAttachmentId } from "data-access/repositories/project/attachment/attachment.dto";
import { projectBillingRepository } from "data-access/repositories/project/project_billing/project_billing.repository";
import { ProjectTodo, ProjectTodoId } from "data-access/repositories/project/todo/todo.dto";
import { ProjectStatusTypeId } from "data-access/repositories/project_status_type/project_status_type.dto";
import { theme } from "extensions/theme";
import { clientServiceReportRepository } from "features/client-service-report/api/client_service_report.repository";
import { ClientServiceReportIndex } from "features/client-service-report/types/client_service_report.dto";
import { deleteConfirmDialogOperations } from "store/delete-confirm-dialog/operations";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { projectSidebarOperations } from "store/project-sidebar/operations";
import { selectProjectSidebar } from "store/project-sidebar/slice";
import { selectProjectTable } from "store/project-table/slice";
import { reportContentPrintModalOperations } from "store/report-content-print-modal/operations";
import useSWR from "swr";
import { downloadBlob } from "utils/downloadBlob";
import { getBaseUrl } from "utils/getBaseUrl";
import { openURLInNewTab } from "utils/openURLInNewTab";

export const ProjectSidebar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const state = useAppSelector(selectProjectSidebar);
  const tableState = useAppSelector(selectProjectTable);
  const mainState = useAppSelector(selectMain);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  /** 原価でTabを押した際、概要・メモがTextFieldでないことによる無反応の対策 */
  const [isNoteEditEnabled, setIsNoteEditEnabled] = useState<boolean>(false);

  const activityFetchKey = `/api/v1/projects/${state.id}/activities`;

  const projectBillingFetchKey = useMemo(() => {
    return `/api/v1/projects/${state.id}/project_billings`;
  }, [state.id]);
  const { data: projectBillings } = useSWR(state.id ? projectBillingFetchKey : null, () =>
    projectBillingRepository.index(state.id as number),
  );
  const { data: rawBuildingsData } = useSWR(state.isOpen ? "/api/v1/buildings/all" : null, () =>
    buildingRepository.all({ orderBy: "recently_created" }),
  );
  const { data: rawClientsData } = useSWR(state.isOpen ? "/api/v1/clients/all" : null, () =>
    clientRepository.all({ orderBy: "recently_created" }),
  );
  const selectedClient = useMemo<Client[]>(() => {
    if (!rawClientsData || !state.project.client) return [];
    return rawClientsData.filter((client) => client.id === state.project.client.id);
  }, [state.project.client, rawClientsData]);

  const browserCallBack = useCallback(() => {
    const confirmResult = confirm(
      "このページを離れると、ファイルのアップロードが中断されますがよろしいですか？",
    );
    // 「OK」→ブラウザバックされ詳細画面が閉じる、「キャンセル」→画面は切り替えない
    if (confirmResult) {
      dispatch(projectSidebarOperations.close());
    } else {
      history.go(1);
    }
  }, []);
  const reloadCallBack = useCallback((e: any) => {
    e.preventDefault();
    e.returnValue = "";
  }, []);

  const preventOperationsDuringFileUpload = () => {
    // ブラウザバックで発火
    window.addEventListener("popstate", browserCallBack);
    // リロードorタブ閉じるときに発火
    window.addEventListener("beforeunload", reloadCallBack);
  };
  const releasePreventOperationsDuringFileUpload = () => {
    window.removeEventListener("popstate", browserCallBack);
    window.removeEventListener("beforeunload", reloadCallBack);
  };

  useEffect(() => {
    if (!location.pathname.includes("projects")) {
      dispatch(projectSidebarOperations.close());
    }
  }, [location]);
  useEffect(() => {
    if (state.project.id) {
      // When handlePhotoUpload use, the component is refreshed and the projectId is no longer retained, so localStorage is used.
      localStorage.setItem("project", JSON.stringify({ id: state.project.id }));
    }
  }, [state.project.id]);
  // 「郵便番号を検索」「住所を検索」成功時に、検索結果で値を更新する
  useEffect(() => {
    if (state.isSearched) {
      dispatch(
        projectSidebarOperations.updateProject(state.project.id, {
          address: state.form.address,
          postalCode: state.form.postalCode,
        }),
      );
    }
  }, [state.isSearched]);

  useEffect(() => {
    if (state.uploadedEstimates.length > 0) {
      preventOperationsDuringFileUpload();
      dispatch(
        projectSidebarOperations.createProjectEstimate(state.project.id, state.uploadedEstimates),
      );
    }
  }, [state.uploadedEstimates]);
  useEffect(() => {
    if (state.uploadedAttachments && state.uploadedAttachments.files.length > 0) {
      preventOperationsDuringFileUpload();
      dispatch(
        projectSidebarOperations.createProjectAttachment(
          state.project.id,
          state.uploadedAttachments,
        ),
      );
    }
  }, [state.uploadedAttachments]);

  // alertを出すイベントを、ファイルアップロード後に削除する
  useEffect(() => {
    if (!state.isFileUploading) {
      releasePreventOperationsDuringFileUpload();
    }
  }, [state.isFileUploading]);
  // 完了日の必須入力エラー時、完了日を入力されるようにする
  useEffect(() => {
    if (state.errorMessage === "ステータスを完了に変更する場合は完了日の入力が必須です") {
      const element = document.getElementById("completedDate");
      if (element) {
        element.click();
      }
    }
  }, [state.errorMessage]);

  useEffect(() => {
    if (state.isTodoSubmitted) {
      dispatch(projectSidebarOperations.showProjectTodo(state.project.id));
    }
  }, [state.isTodoSubmitted]);

  // 案件の新規作成・複製・削除後にurlを変更する
  useEffect(() => {
    if (state.isCreated) {
      navigate(`/projects/${state.id}`);
      dispatch(projectSidebarOperations.updateIsCreated(false));
      return;
    }
    if (tableState.isDestroyed) {
      navigate("/projects");
    }
  }, [state.isCreated, tableState.isDestroyed]);

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
    id?: number | null,
  ) => {
    switch (event.target.name) {
      case "projectTypeId":
        if (state.isExisting) {
          dispatch(
            projectSidebarOperations.updateProject(state.project.id, {
              projectTypeId: event.target.value as number,
            }),
          );
          dispatch(projectSidebarOperations.updateProjectTypeId(event.target.value as number));
        } else {
          dispatch(
            projectSidebarOperations.createProject({
              ...state.form,
              projectTypeId: event.target.value as number,
            }),
          );
        }
        break;
      case "name":
        return dispatch(projectSidebarOperations.updateName(event.target.value as string));
      case "personName":
        return dispatch(projectSidebarOperations.updatePersonName(event.target.value as string));
      case "projectStatusType":
        if (state.isExisting) {
          dispatch(
            projectSidebarOperations.updateProject(state.project.id, {
              projectStatusTypeId: event.target.value as ProjectStatusTypeId,
            }),
          );
          dispatch(
            projectSidebarOperations.updateProjectStatusTypeId(
              event.target.value as ProjectStatusTypeId,
            ),
          );
        } else {
          dispatch(
            projectSidebarOperations.createProject({
              ...state.form,
              projectStatusTypeId: event.target.value as ProjectStatusTypeId,
            }),
          );
        }
        break;
      case "code":
        return dispatch(projectSidebarOperations.updateCode(event.target.value as string));
      case "inquiryNumber":
        return dispatch(projectSidebarOperations.updateInquiryNumber(event.target.value as string));
      case "note":
        return dispatch(projectSidebarOperations.updateNote(event.target.value as string));
      case "billingPrecaution":
        return dispatch(
          projectSidebarOperations.updateBillingPrecaution(event.target.value as string),
        );
      case "postalCode":
        return dispatch(projectSidebarOperations.updatePostalCode(event.target.value as string));
      case "address":
        return dispatch(projectSidebarOperations.updateAddress(event.target.value as string));
      case "addressSecond":
        return dispatch(projectSidebarOperations.updateAddressSecond(event.target.value as string));
      case "phoneNumber":
        return dispatch(projectSidebarOperations.updatePhoneNumber(event.target.value as string));
      case "phoneNumberSecond":
        return dispatch(
          projectSidebarOperations.updatePhoneNumberSecond(event.target.value as string),
        );
      case "faxNumber":
        return dispatch(projectSidebarOperations.updateFaxNumber(event.target.value as string));
      case "email":
        return dispatch(projectSidebarOperations.updateEmail(event.target.value as string));
      case "managerId":
        if (state.isExisting) {
          dispatch(
            projectSidebarOperations.updateProject(state.project.id, {
              managerId: event.target.value as number,
            }),
          );
          dispatch(projectSidebarOperations.updateManagerId(event.target.value as number));
        } else {
          dispatch(
            projectSidebarOperations.createProject({
              ...state.form,
              managerId: event.target.value as number,
            }),
          );
        }
        break;
      case "requester":
        return dispatch(projectSidebarOperations.updateRequester(event.target.value as string));
      case "requestedByPersonName":
        return dispatch(
          projectSidebarOperations.updateRequestedByPersonName(event.target.value as string),
        );
      case "reportContent":
        return dispatch(projectSidebarOperations.updateReportContent(event.target.value as string));
      case "inquiredById":
        if (state.isExisting) {
          dispatch(
            projectSidebarOperations.updateProject(state.project.id, {
              inquiredById: event.target.value as number,
            }),
          );
          dispatch(projectSidebarOperations.updateInquiredById(event.target.value as number));
        } else {
          dispatch(
            projectSidebarOperations.createProject({
              ...state.form,
              inquiredById: event.target.value as number,
            }),
          );
        }
        break;
      case "todoTagType":
        dispatch(
          projectSidebarOperations.updateTodoTagType(id as number, event.target.value as string),
        );
        dispatch(
          projectSidebarOperations.updateProjectTodo(state.project.id, id as ProjectTodoId, {
            tagType: event.target.value as string,
          }),
        );
        break;
      case "todoContent": {
        return dispatch(
          projectSidebarOperations.updateTodoContent(id as number, event.target.value as string),
        );
      }
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    const formKey = e.target.name;
    const projectKey = formKey
      .split(/(?=[A-Z])/)
      .join("_")
      .toLowerCase();

    if (state.project[projectKey] !== state.form[formKey]) {
      if (state.isExisting) {
        dispatch(
          projectSidebarOperations.updateProject(state.project.id, {
            [formKey]: state.form[formKey],
          }),
        );
      } else {
        dispatch(
          projectSidebarOperations.createProject({
            ...state.form,
            [formKey]: state.form[formKey],
          }),
        );
      }
    }
  };

  const handleCreateClient = () => {
    openURLInNewTab(`clients/new/project/${state.project.id}`);
  };

  const handleCreateBuilding = () => {
    openURLInNewTab("buildings/new");
  };

  const handleChangeBuilding = (
    _: React.SyntheticEvent,
    value: any,
    reason: AutocompleteChangeReason,
  ) => {
    if (!rawBuildingsData) return;
    if (reason === "selectOption") {
      const selectBuilding = rawBuildingsData?.filter((building) => {
        return building.id === value.id;
      });
      if (state.isExisting) {
        dispatch(
          projectSidebarOperations.updateProject(state.project.id, {
            name: state.form.name ? state.form.name : selectBuilding[0].name,
            clientId: state.project.client ? state.project.client.id : selectBuilding[0].client?.id,
            buildingId: selectBuilding[0].id,
            personName: selectBuilding[0].person_name
              ? selectBuilding[0].person_name
              : state.form.personName,
            postalCode: selectBuilding[0].postal_code
              ? selectBuilding[0].postal_code
              : state.form.postalCode,
            address: selectBuilding[0].address ? selectBuilding[0].address : state.form.address,
            addressSecond: selectBuilding[0].name
              ? selectBuilding[0].name
              : state.form.addressSecond,
            phoneNumber: selectBuilding[0].phone_number
              ? selectBuilding[0].phone_number
              : state.form.phoneNumber,
            phoneNumberSecond: selectBuilding[0].phone_number_second
              ? selectBuilding[0].phone_number_second
              : state.form.phoneNumberSecond,
            faxNumber: selectBuilding[0].fax_number
              ? selectBuilding[0].fax_number
              : state.form.faxNumber,
            email: selectBuilding[0].email ? selectBuilding[0].email : state.form.email,
          }),
        );
      } else {
        dispatch(
          projectSidebarOperations.createProject({
            ...state.form,
            name: selectBuilding[0].name,
            clientId: selectBuilding[0].client?.id || undefined,
            buildingId: selectBuilding[0].id,
            personName: selectBuilding[0].person_name,
            postalCode: selectBuilding[0].postal_code,
            address: selectBuilding[0].address,
            addressSecond: selectBuilding[0].name,
            phoneNumber: selectBuilding[0].phone_number,
            phoneNumberSecond: selectBuilding[0].phone_number_second,
            faxNumber: selectBuilding[0].fax_number,
            email: selectBuilding[0].email,
          }),
        );
      }
    }
    if (reason === "clear") {
      dispatch(
        projectSidebarOperations.updateProject(state.project.id, {
          buildingId: 0,
        }),
      );
    }
  };
  const handleChangeClient = (
    e: React.SyntheticEvent,
    value: any,
    reason: AutocompleteChangeReason,
  ) => {
    if (!rawClientsData) return;
    if (reason === "selectOption") {
      const selectClient = rawClientsData?.filter((client) => {
        return client.id === value.id;
      });
      if (state.isExisting) {
        dispatch(
          projectSidebarOperations.updateProject(state.project.id, {
            clientId: selectClient[0].id,
          }),
        );
      } else {
        dispatch(
          projectSidebarOperations.createProject({
            ...state.form,
            clientId: selectClient[0].id,
          }),
        );
      }
    }
    if (reason === "clear") {
      dispatch(
        projectSidebarOperations.updateProject(state.project.id, {
          clientId: 0,
        }),
      );
    }
  };

  const handleBlurTodo = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    todoId: number,
  ) => {
    const beforeTodo = state.todos.find((todo: ProjectTodo) => todo.id === todoId);
    dispatch(projectSidebarOperations.updateIsEditMode(todoId, false));

    // 前回の値と同じなら更新しない
    if (beforeTodo?.content === e.target.value) return;

    const currentTodo = state.todoForm.find((todo) => todo.id === todoId);
    dispatch(
      projectSidebarOperations.updateProjectTodo(state.project.id, todoId as ProjectTodoId, {
        content: currentTodo?.content as string,
      }),
    );
  };

  const handleCheckEvent = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    if (!state.isExisting) {
      dispatch(
        projectSidebarOperations.createProject({
          ...state.form,
          [event.target.name]: value,
        }),
      );
      return;
    }
    switch (event.target.name) {
      case "isSupportedByMaker":
        dispatch(
          projectSidebarOperations.updateProject(state.project.id, {
            isSupportedByMaker: value,
          }),
        );
        dispatch(projectSidebarOperations.updateIsSupportedByMaker(value));
        break;
      case "isManageBillingOnAnotherSystem":
        dispatch(
          projectSidebarOperations.updateProject(state.project.id, {
            isManageBillingOnAnotherSystem: value,
          }),
        );
        dispatch(projectSidebarOperations.updateIsManageBillingOnAnotherSystem(value));
        break;
      case "isNoBill":
        dispatch(
          projectSidebarOperations.updateProject(state.project.id, {
            isNoBill: value,
          }),
        );
        dispatch(projectSidebarOperations.updateIsNoBill(value));
        break;
    }
  };

  const handleCheckAttachment = (e: React.ChangeEvent<HTMLInputElement>, attachmentId: number) => {
    dispatch(
      projectSidebarOperations.updateProjectAttachment(
        state.project.id,
        attachmentId as ProjectAttachmentId,
        { isPublishToMobile: e.target.checked },
      ),
    );
  };

  const handleClick = (name: string) => {
    switch (name) {
      case "printableReport":
        return dispatch(reportContentPrintModalOperations.open());
    }
  };

  const handleAttachmentUpload = (files: File[], attachmentTypeId?: string) => {
    if (attachmentTypeId) {
      dispatch(projectSidebarOperations.setUploadedAttachments(files, attachmentTypeId));
    }
  };

  const handleEstimateUpload = (files: File[]) => {
    dispatch(projectSidebarOperations.setUploadedEstimates(files));
  };

  const handleDelete = (id: number, type: string) => {
    dispatch(
      deleteConfirmDialogOperations.setObject({
        id,
        type,
      }),
    );
    dispatch(deleteConfirmDialogOperations.open());
  };

  const handleOnCloseDetailsSidebar = () => {
    if (window.location.pathname.includes("projects")) {
      navigate("/projects");
    }
  };

  const handleClickCreateTodo = () => {
    const body = {
      tagType: "schedule_adjustment",
      content: "",
    };
    dispatch(projectSidebarOperations.createProjectTodo(state.project.id, body));
  };

  const handleCheckTodo = (e: React.ChangeEvent<HTMLInputElement>, todoId: number) => {
    switch (e.target.name) {
      case "isTodoDone":
        return dispatch(
          projectSidebarOperations.updateTodoDone(state.project.id, todoId as ProjectTodoId),
        );
      case "isTodoUndone":
        return dispatch(
          projectSidebarOperations.updateTodoUndone(state.project.id, todoId as ProjectTodoId),
        );
    }
  };

  const handleClickEditModeChange = (todoId: number, value: boolean) => {
    dispatch(projectSidebarOperations.updateIsEditMode(todoId, value));
  };

  const handlePdfDownload = async (clientServiceReport: ClientServiceReportIndex) => {
    const envApiUrl = getBaseUrl();
    window.open(
      `${envApiUrl}/api/v1/client_service_reports/${clientServiceReport.urlKey}/download.pdf`,
      "_blank",
    );
  };

  const handleExcelDownload = async (clientServiceReport: ClientServiceReportIndex) => {
    try {
      const blob = await clientServiceReportRepository.download(
        clientServiceReport.urlKey,
        ".xlsx",
      );
      downloadBlob(blob, `${clientServiceReport.format.name} 報告書.xlsx`);
      dispatch(mainOperations.updateSuccessMessage("ダウンロードしました"));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(mainOperations.updateErrorMessage("ダウンロードに失敗しました"));
    }
  };

  const isPhotoDisplay = useMemo(() => {
    return state.isExisting;
  }, [state.isExisting]);

  const handleCloseProjectSuccessSnackBar = () => {
    dispatch(projectSidebarOperations.updateSuccessMessage(""));
  };
  const handleCloseProjectErrorSnackBar = () => {
    dispatch(projectSidebarOperations.updateErrorMessage(""));
  };

  return (
    <>
      {mainState.company.company_setting.is_printable_report_use && (
        <ReportContentPrintModal client={selectedClient[0]} />
      )}
      <DiscardChangesConfirmDialog />
      <DeleteConfirmDialog />
      <ImagePreviewModal />

      <DetailSidebar objectName="project" onClose={handleOnCloseDetailsSidebar}>
        <ResponseSnackbar
          successMessage={state.successMessage}
          errorMessage={state.errorMessage}
          handleCloseSuccess={handleCloseProjectSuccessSnackBar}
          handleCloseError={handleCloseProjectErrorSnackBar}
        />
        <ResponseSnackbar
          successMessage={mainState.successMessage}
          errorMessage={mainState.errorMessage}
        />
        <ResponseSnackbar
          errorMessage={errorMessage}
          handleCloseError={() => setErrorMessage("")}
        />
        <Backdrop
          sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
          open={state.isLoading || isLoading}
          invisible
        >
          <CircularProgress />
        </Backdrop>

        <ProjectSidebarHeader
          projectForm={state.form}
          onChange={handleChange}
          onBlur={handleBlur}
          onChangeBuilding={handleChangeBuilding}
          onCreateBuilding={handleCreateBuilding}
          onChangeClient={handleChangeClient}
          onCreateClient={handleCreateClient}
          onClose={handleOnCloseDetailsSidebar}
          projectStatusTypes={mainState.projectStatusTypes}
          rawClientsData={rawClientsData || []}
          rawBuildingsData={rawBuildingsData || []}
          projectBillings={projectBillings || []}
          selectedClient={selectedClient[0]}
        />
        <ProjectSidebarTitle
          onChange={handleChange}
          onBlur={handleBlur}
          state={state}
          projectTypes={mainState.projectTypes}
          projectForm={state.form}
          photoInfo={state.project.photo_info_v2}
          isPhotoDisplay={isPhotoDisplay}
          users={mainState.users}
          activityFetchKey={activityFetchKey}
        />
        <Divider sx={{ my: "24px" }} />
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "41rem" }}>
            <TitleLabel title="基本情報" />
            <ProjectSidebarSalesBlock
              salesAmount={state.form.salesAmount || 0}
              tax={state.form.tax || 0}
              totalCostAmount={state.form.totalCostAmount || 0}
              decidedEstimatesTotalAmount={state.project.decided_estimates_total_amount}
              projectId={state.project.id}
              onBlurSalesAmount={() => {
                setIsNoteEditEnabled(true);
              }}
            />
            <ProjectSidebarContentDetailsBlock
              onChange={handleChange}
              onBlur={handleBlur}
              projectForm={state.form}
              isNoteEditEnabled={isNoteEditEnabled}
              setIsNoteEditEnabled={setIsNoteEditEnabled}
            />
            <Divider sx={{ my: "24px" }} />
            <ProjectSidebarSiteInfo
              onChange={handleChange}
              onBlur={handleBlur}
              onChangeBuilding={handleChangeBuilding}
              onCreate={handleCreateBuilding}
              state={state}
              projectForm={state.form}
              rawBuildingsData={rawBuildingsData || []}
            />

            {projectBillings && (
              <>
                <Divider sx={{ my: "24px" }} />
                <BillingsInfo
                  state={state}
                  projectBillings={projectBillings}
                  projectForm={state.form}
                  rawClientsData={rawClientsData || []}
                  selectedClient={selectedClient[0]}
                  fetchKey={projectBillingFetchKey}
                  onClick={handleClick}
                  onCheck={handleCheckEvent}
                />
              </>
            )}

            <Divider sx={{ mt: "24px" }} />

            {mainState.company.company_setting.is_printable_report_use && (
              <>
                <ProjectSidebarPrintableReportBlock
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onClick={handleClick}
                  projectForm={state.form}
                />
                <Divider sx={{ mt: "1rem" }} />
              </>
            )}
            {state.isExisting && <ProjectSidebarActivity state={state} />}
          </Box>

          <Divider orientation="vertical" variant="middle" sx={{ mx: "16px" }} />

          <Box sx={{ py: "22px", width: "20rem" }}>
            <ProjectSidebarTodo
              state={state}
              projectTodoTagTypes={mainState.projectTodoTagTypes}
              onChange={handleChange}
              onCheck={handleCheckTodo}
              onClickCreate={handleClickCreateTodo}
              onClickDelete={handleDelete}
              onClickEditModeChange={handleClickEditModeChange}
              onBlur={handleBlurTodo}
            />
            <Divider sx={{ my: "2rem" }} />
            <ProjectSidebarScheduleBlock
              state={state}
              projectId={state.project.id}
              projectName={state.project.name}
            />
            <Divider sx={{ my: "2rem" }} />
            <ProjectSidebarEstimateBlock
              state={state}
              mainState={mainState}
              onUpload={handleEstimateUpload}
              activityFetchKey={activityFetchKey}
            />
            <Divider sx={{ my: "2rem" }} />
            <ProjectSidebarWorkReportBlock state={state} projectId={state.project.id} />
            <Divider sx={{ my: "2rem" }} />
            <ProjectSidebarClientServiceReportBlock
              state={state}
              setIsLoading={setIsLoading}
              onDownloadPdf={handlePdfDownload}
              onDownloadExcel={handleExcelDownload}
            />
            <Divider sx={{ my: "2rem" }} />
            <ProjectSidebarAttachmentBlock
              state={state}
              onUpload={handleAttachmentUpload}
              onDelete={handleDelete}
              onCheck={handleCheckAttachment}
            />
          </Box>
        </Box>
      </DetailSidebar>
    </>
  );
};
